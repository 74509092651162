export enum HandleStatus {
  Missed = 'missed',
  Won = 'won',
  Lost = 'lost',
  Ongoing = 'ongoing',
  Upcoming = 'upcoming'
}

export interface StreakDay {
  status: HandleStatus;
  dayName: string;
}

export interface Deal {
  // TODO: Add properties
  // ...
}

export interface Handle {
  id: number;
  date: string;
  difficultyLevel: number;
  deal: Deal;
  status: HandleStatus;
  bonusTricks: number | null;
  madeMaximumTricks: boolean;
  runThroughVideoUrl: string | null;
}

export interface Tournament {
  id: number;
  isPublished: boolean;
  week: number;
  handles: Handle[];
}

export interface RoomTokenPayload {
  platform: string;
  room_id: string;
  iat: number;
  exp: number;
}

export interface RoomTokenData {
  profileId: number;
  handleId: number;
  roomToken: string;
  expiresAt: number;
}

export interface CreateRoomPayload {
  metadata?: Record<string, any>;
  exitRedirectUrl?: string;
  exitButtonText?: string;
}

export interface CreateRoomResponse {
  roomToken: string;
}

export interface AnonymousScoreExistsResponse {
  exists: boolean;
}
