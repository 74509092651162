export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Welcome to Handle, a daily deal to test your card-play."},{"name":"og:image","content":"https://handle.bridge128.com/images/logo.png"},{"name":"robots","content":"noindex, nofollow"},{"name":"facebook-domain-verification","content":"8z7pc9pha12x66j9570hchi4ykr5ge"},{"name":"msapplication-TileColor","content":"#da532c"},{"name":"msapplication-config","content":"/browserconfig.xml"},{"name":"theme-color","content":"#ffffff"},{"name":"apple-mobile-web-app-title","content":"Handle"},{"name":"application-name","content":"Handle"}],"link":[{"rel":"icon","type":"image/png","sizes":"16x16","href":"/icons/meta/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/icons/meta/favicon-32x32.png"},{"rel":"icon","sizes":"192x192","href":"/icons/meta/android-chrome-192x192.png"},{"rel":"icon","sizes":"256x256","href":"/icons/meta/android-chrome-256x256.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/icons/meta/apple-touch-icon.png"},{"rel":"mask-icon","href":"/icons/meta/safari-pinned-tab.svg","color":"#000f76"}],"style":[],"script":[{"src":"https://cdn.jsdelivr.net/npm/path2d-polyfill/dist/path2d-polyfill.min.js"},{"type":"text/javascript","children":"var _iub = _iub || [];_iub.csConfiguration = {\"siteId\":3665988,\"cookiePolicyId\":82200802,\"lang\":\"en-GB\"};"},{"type":"text/javascript","src":"https://cs.iubenda.com/autoblocking/3665988.js"},{"type":"text/javascript","src":"//cdn.iubenda.com/cs/gpp/stub.js"},{"type":"text/javascript","src":"//cdn.iubenda.com/cs/iubenda_cs.js","async":true}],"noscript":[]}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false