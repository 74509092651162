import { default as courses18OWjrvFATMeta } from "/tmp/build_8c54820f/pages/courses.vue?macro=true";
import { default as _91id_93znX1qxKBLuMeta } from "/tmp/build_8c54820f/pages/gift-card/[id].vue?macro=true";
import { default as _91token_93hSHLkO2qQZMeta } from "/tmp/build_8c54820f/pages/gift-card/claim/[token].vue?macro=true";
import { default as indexekiddlf7GJMeta } from "/tmp/build_8c54820f/pages/gift-card/index.vue?macro=true";
import { default as indexwyTY3vJA86Meta } from "/tmp/build_8c54820f/pages/index.vue?macro=true";
import { default as loginhXiqogritXMeta } from "/tmp/build_8c54820f/pages/login.vue?macro=true";
import { default as playNmJGEBxj3HMeta } from "/tmp/build_8c54820f/pages/play.vue?macro=true";
import { default as handleGBLmXWs2peMeta } from "/tmp/build_8c54820f/pages/settings/handle.vue?macro=true";
import { default as indexbKhgLTaTg2Meta } from "/tmp/build_8c54820f/pages/settings/index.vue?macro=true";
import { default as sign_45upurWCOWCu3PMeta } from "/tmp/build_8c54820f/pages/sign-up.vue?macro=true";
import { default as statisticMGBb4L1fnWMeta } from "/tmp/build_8c54820f/pages/statistic.vue?macro=true";
import { default as tournamenthrtcvhemhwMeta } from "/tmp/build_8c54820f/pages/tournament.vue?macro=true";
export default [
  {
    name: courses18OWjrvFATMeta?.name ?? "courses",
    path: courses18OWjrvFATMeta?.path ?? "/courses",
    meta: courses18OWjrvFATMeta || {},
    alias: courses18OWjrvFATMeta?.alias || [],
    redirect: courses18OWjrvFATMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/courses.vue").then(m => m.default || m)
  },
  {
    name: _91id_93znX1qxKBLuMeta?.name ?? "gift-card-id",
    path: _91id_93znX1qxKBLuMeta?.path ?? "/gift-card/:id()",
    meta: _91id_93znX1qxKBLuMeta || {},
    alias: _91id_93znX1qxKBLuMeta?.alias || [],
    redirect: _91id_93znX1qxKBLuMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/gift-card/[id].vue").then(m => m.default || m)
  },
  {
    name: _91token_93hSHLkO2qQZMeta?.name ?? "gift-card-claim-token",
    path: _91token_93hSHLkO2qQZMeta?.path ?? "/gift-card/claim/:token()",
    meta: _91token_93hSHLkO2qQZMeta || {},
    alias: _91token_93hSHLkO2qQZMeta?.alias || [],
    redirect: _91token_93hSHLkO2qQZMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/gift-card/claim/[token].vue").then(m => m.default || m)
  },
  {
    name: indexekiddlf7GJMeta?.name ?? "gift-card",
    path: indexekiddlf7GJMeta?.path ?? "/gift-card",
    meta: indexekiddlf7GJMeta || {},
    alias: indexekiddlf7GJMeta?.alias || [],
    redirect: indexekiddlf7GJMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/gift-card/index.vue").then(m => m.default || m)
  },
  {
    name: indexwyTY3vJA86Meta?.name ?? "index",
    path: indexwyTY3vJA86Meta?.path ?? "/",
    meta: indexwyTY3vJA86Meta || {},
    alias: indexwyTY3vJA86Meta?.alias || [],
    redirect: indexwyTY3vJA86Meta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhXiqogritXMeta?.name ?? "login",
    path: loginhXiqogritXMeta?.path ?? "/login",
    meta: loginhXiqogritXMeta || {},
    alias: loginhXiqogritXMeta?.alias || [],
    redirect: loginhXiqogritXMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/login.vue").then(m => m.default || m)
  },
  {
    name: playNmJGEBxj3HMeta?.name ?? "play",
    path: playNmJGEBxj3HMeta?.path ?? "/play",
    meta: playNmJGEBxj3HMeta || {},
    alias: playNmJGEBxj3HMeta?.alias || [],
    redirect: playNmJGEBxj3HMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/play.vue").then(m => m.default || m)
  },
  {
    name: handleGBLmXWs2peMeta?.name ?? "settings-handle",
    path: handleGBLmXWs2peMeta?.path ?? "/settings/handle",
    meta: handleGBLmXWs2peMeta || {},
    alias: handleGBLmXWs2peMeta?.alias || [],
    redirect: handleGBLmXWs2peMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/settings/handle.vue").then(m => m.default || m)
  },
  {
    name: indexbKhgLTaTg2Meta?.name ?? "settings",
    path: indexbKhgLTaTg2Meta?.path ?? "/settings",
    meta: indexbKhgLTaTg2Meta || {},
    alias: indexbKhgLTaTg2Meta?.alias || [],
    redirect: indexbKhgLTaTg2Meta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: sign_45upurWCOWCu3PMeta?.name ?? "sign-up",
    path: sign_45upurWCOWCu3PMeta?.path ?? "/sign-up",
    meta: sign_45upurWCOWCu3PMeta || {},
    alias: sign_45upurWCOWCu3PMeta?.alias || [],
    redirect: sign_45upurWCOWCu3PMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/sign-up.vue").then(m => m.default || m)
  },
  {
    name: statisticMGBb4L1fnWMeta?.name ?? "statistic",
    path: statisticMGBb4L1fnWMeta?.path ?? "/statistic",
    meta: statisticMGBb4L1fnWMeta || {},
    alias: statisticMGBb4L1fnWMeta?.alias || [],
    redirect: statisticMGBb4L1fnWMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/statistic.vue").then(m => m.default || m)
  },
  {
    name: tournamenthrtcvhemhwMeta?.name ?? "tournament",
    path: tournamenthrtcvhemhwMeta?.path ?? "/tournament",
    meta: tournamenthrtcvhemhwMeta || {},
    alias: tournamenthrtcvhemhwMeta?.alias || [],
    redirect: tournamenthrtcvhemhwMeta?.redirect,
    component: () => import("/tmp/build_8c54820f/pages/tournament.vue").then(m => m.default || m)
  }
]