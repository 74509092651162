export const sleep = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export const waitUntil = (conditionFn: () => boolean) => {
  return new Promise((resolve) => {
    if (conditionFn()) {
      return resolve(true);
    }
    const interval = setInterval(() => {
      if (conditionFn()) {
        clearInterval(interval);
        return resolve(true);
      }
    }, 50);
  });
};

export function debouncePromise(inner: Function, ms = 0) {
  let timer: NodeJS.Timeout | null = null;
  let resolves: any[] = [];

  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      const result = inner();
      resolves.forEach((r) => r(result));
      resolves = [];
    }, ms);

    return new Promise((resolve) => resolves.push(resolve));
  };
}

export function createImageElementFromSVG(
  svgString: string,
  width?: number,
  height?: number
): Promise<HTMLImageElement> {
  return new Promise((resolve) => {
    const iconElement = new Image(width, height);
    const encodedIcon = btoa(svgString);
    iconElement.addEventListener('load', () => {
      resolve(iconElement);
    });
    iconElement.src = `data:image/svg+xml;base64,${encodedIcon}`;
  });
}

export const showDecimals = (value: number, decimals = 0): string => {
  return (Math.round(value * 100) / 100).toFixed(decimals);
};

export function nonNullable<T>(value: T): value is NonNullable<T> {
  return value !== null && value !== undefined;
}
