<template>
  <AppContainer>
    <AppHeader />
    <div class="min-h-full flex-1 mb-auto bg-brand-white">
      <Container class-name="h-full flex py-5 lg:py-12">
        <slot />
      </Container>
    </div>
    <AppFooter />
  </AppContainer>
</template>
